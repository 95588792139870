import React from "react";
import Responsive from "semantic-ui-react/dist/es/addons/Responsive";

import dayjs from "dayjs";

import firebase from "../firebase"
import CalendarView from "./CalendarView";
import EventsListView from "./EventsListView";

export default class EventsListContainer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      events: []
    }
  }
  
  async componentDidMount() {
    let fb = await firebase;
    
    let q = fb.firestore().collection("/events").orderBy("startTime").where("startTime", ">=", dayjs().set("date", 1).set("hour", 0).set("minute", 0).toDate()).where("public", "==", true);
    q.onSnapshot((snap) => {
      this.setState({
        events: snap.docs.map((e) => {
          let event = e.data();
          event.id = e.id;
          return event
        })
      })
    })
  }
  
  render() {
    return <>
      <Responsive minWidth={970}><CalendarView events={this.state.events} /></Responsive>
      <Responsive maxWidth={969}><EventsListView events={this.state.events} /></Responsive>
    </>
  }
}