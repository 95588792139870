import React from "react";
import BigCalendar from "react-big-calendar";
import {DateLocalizer} from "react-big-calendar/lib/localizer"
import dates from "react-big-calendar/lib/utils/dates";
import dayjs from "dayjs";
import {navigate, Link} from "@reach/router";

import "react-big-calendar/lib/css/react-big-calendar.css";

const CalendarView = ({events}) => {
  return <div style={{height: "100vh"}}>
      <BigCalendar
        localizer={getLocalizer()}
        events={events.map((e) => ({
      title: e.name,
      start: e.startTime.toDate(),
      end: e.endTime.toDate(),
      resource: e.id
    }))}
        defaultDate={new Date()} onSelectEvent={(event) => {
          navigate(`/events/${event.resource}`)
      }} eventPropGetter={(event) => {
        if(event.start.getTime() < new Date().getTime()) {
          return {
            style: {
              opacity: 0.8,
              backgroundColor: "rgb(150, 150, 150)"
            }
          }
        }
      }} components={{
        month: {
          event: ({event, title}) => {
            const time = dayjs(event.start).format("HH:mm");
            return <div>{time} {title}</div>
          }
        },
        agenda: {
          event: ({event, title}) => {
            return <Link to={`/events/${event.resource}`}>{title}</Link>
          }
        }
      }}/>
  </div>
};

export default CalendarView;

function getLocalizer() {
  let dateRangeFormat = ({start, end}, culture, local) =>
    local.format(start, 'DD/MM/YYYY', culture) + ' — ' + local.format(end, 'DD/MM/YYYY', culture)
  
  let timeRangeFormat = ({start, end}, culture, local) =>
    local.format(start, 'HH:mm', culture) + ' — ' + local.format(end, "HH:mm", culture)
  
  let timeRangeStartFormat = ({start}, culture, local) =>
    local.format(start, 'HH:mm', culture) + ' — '
  
  let timeRangeEndFormat = ({end}, culture, local) =>
    ' — ' + local.format(end, 'HH:mm', culture)
  
  let weekRangeFormat = ({start, end}, culture, local) =>
    local.format(start, 'MMMM DD', culture) +
    ' - ' +
    local.format(end, dates.eq(start, end, 'month') ? 'DD' : 'MMMM DD', culture)
  
  
  let formats = {
    dateFormat: 'DD',
    dayFormat: 'DD ddd',
    weekdayFormat: 'ddd',
    
    selectRangeFormat: timeRangeFormat,
    eventTimeRangeFormat: timeRangeFormat,
    eventTimeRangeStartFormat: timeRangeStartFormat,
    eventTimeRangeEndFormat: timeRangeEndFormat,
    
    timeGutterFormat: 'HH:mm',
    
    monthHeaderFormat: 'MMMM YYYY',
    dayHeaderFormat: 'dddd MMM DD',
    dayRangeHeaderFormat: weekRangeFormat,
    agendaHeaderFormat: dateRangeFormat,
    
    agendaDateFormat: 'ddd MMM DD',
    agendaTimeFormat: 'HH:mm',
    agendaTimeRangeFormat: timeRangeFormat,
  };
  
  return new DateLocalizer({
    formats: formats,
    firstOfWeek: () => 1,
    format: (value, format) => dayjs(value).format(format)
  })
}