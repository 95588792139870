import React from "react";

import Container from "semantic-ui-react/dist/es/elements/Container";
import Menu from "semantic-ui-react/dist/es/collections/Menu";
import Segment from "semantic-ui-react/dist/es/elements/Segment";
import {Link, Router, Match, Redirect} from "@reach/router";

import AdminsManager from "./AdminsManager"
import HoursOverview from "./HoursOverview";

const AdminPage = () => {
  return <Container>
    <Match path="/admin/:section">
      {({match}) => <Menu attached="top" tabular>
      <Menu.Item as={Link} to="/admin/hours" active={match && match.section === "hours"}>Hours</Menu.Item>
      <Menu.Item as={Link} to="/admin/permissions" active={match && match.section === "permissions"}>Admin Permissions</Menu.Item>
    </Menu>}
    </Match>
    <Segment attached="bottom">
      <Router>
        <HoursOverview path="/hours"/>
        <AdminsManager path="/permissions"/>
        <Redirect from="/" to="/admin/hours"/>
      </Router>
    </Segment>
  </Container>
};

export default AdminPage;
