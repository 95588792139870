import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

window.firebase = firebase;

firebase.initializeApp({
  "apiKey": "AIzaSyBr8y6gPIHf9axkSjbeCdlf490bneo3aLk",
  "databaseURL": "https://kaleido-maastricht.firebaseio.com",
  "storageBucket": "kaleido-maastricht.appspot.com",
  "authDomain": "kaleido-maastricht.firebaseapp.com",
  "messagingSenderId": "1036933939604",
  "projectId": "kaleido-maastricht"
});

firebase.firestore().settings({
  timestampsInSnapshots: true
});

window.fireubase = firebase;

export default firebase;