import React from "react";

import Header from "semantic-ui-react/dist/es/elements/Header";
import Grid from "semantic-ui-react/dist/es/collections/Grid";

import firebase from "../firebase";
import Attendance from "./Attendance";
import {sortBy, groupBy} from "../arrayUtils";
import {rangeString} from "./helpers";


export default class EventShifts extends React.Component {
  constructor(props) {
    super();
    
    this.state = {
      shifts: []
    }
  }
  
  componentDidMount() {
    firebase.firestore().collection("shifts").where("event", "==", this.props.eventId).onSnapshot((querySnap) => {
      let shifts = querySnap.docs.map((docSnap) => {
        let data = docSnap.data();
        return {
          ...data,
          id: docSnap.id,
          startTime: data.startTime.toDate(),
          endTime: data.endTime.toDate()
        };
      });
      shifts = sortBy(shifts, (shift) => shift.startTime);
      shifts = groupBy(shifts, (shift) => shift.role);
      
      this.setState({
        shifts: shifts
      })
    })
  }
  
  render() {
    return <React.Fragment>
      <Header as="h2" size="small">Shifts</Header>
      {this.state.shifts.map(([role, shifts]) => {
        // semantic-ui adds margin to things in a card, which messes with the grid.
        let columnStyleOverride = {marginRight: 0};
        let rowStyleOverride = {...columnStyleOverride, padding: 0};
        return <React.Fragment key={role}>
          <Header as="h3" size="tiny" sub style={{marginBottom:"1rem"}}>{role}</Header>
          <Grid style={{maxWidth: "50em"}} celled>
            {shifts.map((shift) => {
              return <Grid.Row style={rowStyleOverride} verticalAlign="middle">
                <Grid.Column width={12} style={columnStyleOverride}>
                  {rangeString(shift.startTime, shift.endTime)}
                </Grid.Column>
                <Grid.Column width={4} style={columnStyleOverride}>
                  <div style={{float: "right"}}><Attendance shiftId={shift.id} compact /></div>
                </Grid.Column>
              </Grid.Row>
            })}
          </Grid>
        </React.Fragment>
      })}
    </React.Fragment>
  }
}