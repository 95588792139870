import React, {useState, useRef, useEffect} from "react";
import {useCollection} from "react-firebase-hooks/firestore";
import Dropdown from "semantic-ui-react/dist/es/modules/Dropdown";

import firebase from "../firebase";


const noop = () => {};
const UserSearch = ({multiple=false, onChange=noop, defaultValue}) => {
  let {value: usersSnapshot, loading, error} = useCollection(firebase.firestore().collection("users"));
  if(error) {
    console.error("Couldn't load users", error);
  }
  
  
  let users = [];
  if(!loading) {
    users = usersSnapshot.docs.map((snapshot) => {
      return {...snapshot.data(), id: snapshot.id}
    })
  }
  
  return <Dropdown options={users.map((user) => {
                     return {
                       text: user.name,
                       image: {src: user.image_url},
                       value: user.id
                     }
                   })}
                   multiple={multiple}
                   search
                   selection
                   fluid
                   defaultValue={defaultValue}
                   onChange={(e, {value}) => onChange(value)}/>
};

export default UserSearch;

