import React from "react";

import Form from "semantic-ui-react/dist/es/collections/Form";
import Input from "semantic-ui-react/dist/es/elements/Input";
import Table from "semantic-ui-react/dist/es/collections/Table";
import Header from "semantic-ui-react/dist/es/elements/Header";
import Button from "semantic-ui-react/dist/es/elements/Button";
import Divider from "semantic-ui-react/dist/es/elements/Divider";
import DatePicker from "../forms/DateTimePicker";


import {groupBy, sortBy} from "../arrayUtils";

export default class EventShiftsEditor extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      shifts: sortBy(props.shifts || [], shift => shift.startTime),
      newRole: "",
    }
    
  }
  
  render() {
    return <>
      <Header as="h2">Work Shifts</Header>
  
      <Form.Group onSubmit={this.addRole}>
        <Form.Input value={this.state.newRole}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.addRole();
                      }
                    }}
                    onChange={(e, {value}) => {
                      this.setState({newRole: value})
                    }} placeholder={"Bar, Ticket desk, Media, ..;"}/>
        <Form.Button type="submit" content="Add Role" secnodary
                     onClick={this.addRole.bind(this)}/>
      </Form.Group>
      
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Start</Table.HeaderCell>
            <Table.HeaderCell>End</Table.HeaderCell>
            <Table.HeaderCell># People Needed</Table.HeaderCell>
            <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        {groupBy(this.state.shifts, (shift) => shift.role).map(([role, shifts, indexes]) => {
          return <React.Fragment key={role}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="4">
                    {role}
                    <Button content="Add Shift"
                            onClick={this.addShift.bind(this, role)}
                            floated="right"
                            icon="plus"
                            positive
                            basic
                            size="mini"/>
                  </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
              {shifts.map((_, i) => {
                return <Table.Row key={`shift-${role}-${i}`}>
                  <Table.Cell>{this.getDatePicker(indexes[i], "startTime")}</Table.Cell>
                  <Table.Cell>{this.getDatePicker(indexes[i], "endTime")}</Table.Cell>
                  <Table.Cell>
                    <Input type="number" value={this.state.shifts[indexes[i]].capacity}
                           onChange={(e, {value}) => this.handleChange(indexes[i], "capacity", value)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Button icon="trash"
                            size="mini"
                            compact
                            negative
                            aria-label="delete shift"
                            onClick={() => this.deleteShift(indexes[i])}/>
                  </Table.Cell>
                </Table.Row>
              })}
            </Table.Body>
          </React.Fragment>
        })}
      </Table>
      <p>You can set the "# people needed" column to 0 to not limit the number of people who can register.</p>
      <Divider />
    </>
  }
  
  getDatePicker(shiftIdx, field) {
    return <DatePicker
      selected={this.state.shifts[shiftIdx][field]}
      onChange={(value) => this.handleChange(shiftIdx, field, value)}
      includeTime={true}
    />
  }
  
  handleChange(shiftIdx, field, value) {
    let shifts = this.state.shifts.slice();
    shifts.splice(shiftIdx, 1, {...shifts[shiftIdx], [field]: value});
    this.setState({shifts}, () => this.props.onChange(this.state.shifts));
  }
  
  addRole() {
    this.addShift(this.state.newRole)
  }
  
  addShift(role) {
    let shifts = this.state.shifts.slice();
    shifts.push({
      startTime: this.props.event.startTime,
      endTime: this.props.event.endTime,
      role: role,
      capacity: 0
    });
    
    this.setState({shifts: sortBy(shifts, shift => shift.startTime), newRole: ""}, () => this.props.onChange(this.state.shifts));
  }
  
  deleteShift(index) {
    let shifts = this.state.shifts.slice();
    shifts.splice(index, 1);
    this.setState({shifts}, () => this.props.onChange(this.state.shifts));
  }
}