import React from "react";
import Container from "semantic-ui-react/dist/es/elements/Container";
import Header from "semantic-ui-react/dist/es/elements/Header";
import Loader from "semantic-ui-react/dist/es/elements/Loader";
import {navigate} from "@reach/router";

import firebase from "../firebase";
import Editor from "./Editor";

export default class UpdateEvent extends React.Component {
  constructor() {
    super();
    
    this.state = {
      event: null,
      shifts: [],
      loading: true,
      saving: false
    };
    
    this.handleSave = this.handleSave.bind(this);
  }
  
  async componentDidMount() {
    this.docRef = firebase.firestore().doc(`/events/${this.props.id}`);
    let shiftsQuery = firebase.firestore().collection("shifts").where("event", "==", this.props.id);
    
    let [eventSnapshot, shiftsQuerySnapshot] = await Promise.all([this.docRef.get(),
                                                             shiftsQuery.get()]);
    let event = eventSnapshot.data();
    event.startTime = event.startTime.toDate();
    event.endTime = event.endTime.toDate();
    this.setState({
      event: event,
      shifts: shiftsQuerySnapshot.docs.map((snap) => {
        let shift = snap.data();
        shift.id = snap.id;
        shift.startTime = shift.startTime.toDate();
        shift.endTime = shift.endTime.toDate();
        return shift;
      }),
      loading: false
    })
  }
  
  render() {
    return <Container>
      <Header as="h1">Edit Event</Header>
      <Loader active={this.state.loading}/>
      {this.state.event ? <Editor event={this.state.event} onSave={this.handleSave} shifts={this.state.shifts}
                                  saving={this.state.saving} isUpdate  /> : null}
    </Container>
  }
  
  async handleSave(data, shifts=[]) {
    this.setState({saving: true})
    let updatePromise = this.docRef.update(data);
    
    // Save new and updated shifts.
    let shiftsCol = firebase.firestore().collection("shifts");
    let saveShiftsPromise = Promise.all(shifts.map((shift) => {
      shift.event = this.props.id;
      
      if(!shift.id) {
        return shiftsCol.add(shift);
      }
      
      let docRef = shiftsCol.doc(shift.id);
      delete shift.id;
      return docRef.set(shift);
    }));
    
    // Delete all shifts that the user has marked for deletion
    let deleteShiftsPromise = Promise.all(this.state.shifts.filter(({id}) => {
      // Any shift that has no corresponding shift (with the same id) returned by the editor,
      // should be deleted.
      if(shifts.filter(s => s.id === id).length === 0){
        return shiftsCol.doc(id).delete();
      }
      return null;
    }));
    
    try {
      await Promise.all([updatePromise, saveShiftsPromise, deleteShiftsPromise]);
      navigate(`/events/${this.props.id}`);
    } catch {
      //TODO: display saving error when updating an event fails.
    }
    this.setState({saving: false})
  }
}