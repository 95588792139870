export const sortBy = (arr, key) => arr.sort((a, b) => key(a) > key(b) ? 1 : -1);

export const groupBy = (arr, key) => {
  let keyIndex = {};
  let result = [];
  
  arr.forEach((el, elIdx) => {
    let kv = key(el);
    if(!keyIndex.hasOwnProperty(kv)){
      keyIndex[kv] = result.length;
      result.push([kv, [], []]);
    }
  
    result[keyIndex[kv]][1].push(el);
    result[keyIndex[kv]][2].push(elIdx)
  });
  
  return result;
};