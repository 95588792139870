import React from "react";
import Feed from "semantic-ui-react/dist/es/views/Feed";
import Icon from "semantic-ui-react/dist/es/elements/Icon";
import {Link} from "@reach/router";

import {rangeString} from "./helpers";

const EventsListView = ({events}) => {
  events = events.filter((e) => e.startTime.toDate() > new Date());
  return <Feed>
    {events.map((event) => {
      return <Feed.Event key={event.id}>
        <Feed.Label><Icon name="calendar"/></Feed.Label>
        <Feed.Content>
          <Feed.Summary><Link
            to={`/events/${event.id}`}>{event.name}</Link></Feed.Summary>
          <Feed.Date>{rangeString(event.startTime.toDate(), event.endTime.toDate())}</Feed.Date>
        </Feed.Content>
      </Feed.Event>
    })}
  </Feed>
};

export default EventsListView;