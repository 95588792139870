import React, { Component } from 'react';
import {Router} from "@reach/router";

import Container from "semantic-ui-react/dist/es/elements/Container";

import AuthContainer from "./AuthContainer";

import Menu from "./Menu";
import Footer from "./Footer";

import "./App.css"

import "semantic-ui-css/semantic.min.css"

import CreateEvent from "./events/CreateEvent";
import UpdateEvent from "./events/UpdateEvent";
import EventPage from "./events/EventPage";
import CloseEventPage from "./events/CloseEventPage";
import EventsList from "./events/EventsListContainer";

import UserLogs from "./time_logs/UserLogs";

import AdminPage from "./admin/AdminPage";

window.AdminPage = AdminPage;

class App extends Component {
  render() {
    return <Container className="container" fluid>
      <AuthContainer>
        <Menu/>
        <Router>
          <EventsList path="/" />
          <CreateEvent path="/events/new" />
          <UpdateEvent path="/events/:id/edit"/>
          <EventPage path="/events/:id"/>
          <CloseEventPage path="/events/:id/close"/>
          
          <UserLogs path="/logs" />
          <UserLogs path="/logs/:uid" />
          
          <AdminPage path="/admin/*" />
        </Router>
        <Footer />
      </AuthContainer>
    </Container>
  }
}


export default App;
