import React, {useState} from "react";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {navigate} from "@reach/router"

import Container from "semantic-ui-react/dist/es/elements/Container";
import Message from "semantic-ui-react/dist/es/collections/Message";
import Header from "semantic-ui-react/dist/es/elements/Header";
import Button from "semantic-ui-react/dist/es/elements/Button";
import Loader from "semantic-ui-react/dist/es/elements/Loader";
import Grid from "semantic-ui-react/dist/es/collections/Grid";

import firebase from "../firebase";
import {groupBy, sortBy} from "../arrayUtils";
import {rangeString} from "./helpers";
import UserSearch from "../users/Search";

let firestore = firebase.firestore();
let closeEvent = firebase.functions().httpsCallable("events-closeEvent");

const CloseEventPage = ({id}) => {
  let [state, setState] = useState(null);
  
  let {value: shiftsSnapshot, loading: shiftsLoading, error: shiftsError} = useCollection(firestore.collection("shifts").where("event", "==", id));
  let {value: eventSnapshot, loading: eventLoading, error: eventError} = useDocument(firestore.doc("events/" + id));
  
  if(state === null && !shiftsLoading) {
    console.log("initializing state.");
    requestAnimationFrame(() => {
      setState(shiftsSnapshot.docs.reduce((obj, shiftSnap) => {
        let attendees = shiftSnap.data().attendees;
        return {...obj, [shiftSnap.id]: Object.keys(attendees).filter((uid) => attendees[uid])};
      }, {}))
    });
  }
  
  if (shiftsLoading || eventLoading || state === null) {
    return <Loader size="huge" content="Loading event"/>
  }
  
  if(shiftsError || eventError) {
    console.error(shiftsError, eventError);
    return <Message error header="Couldn't load the event"
                    content="This might be because the event doesn't exist, or because you don't have permission to see it."/>
  }
  
  let groupedShifts = groupBy(sortBy(shiftsSnapshot.docs.map((docSnap) => {
    let shift = docSnap.data();
    shift.id = docSnap.id;
    shift.startTime = shift.startTime.toDate();
    shift.endTime = shift.endTime.toDate();
    return shift;
  }), (s) => s.startTime), (s) => s.role);
  
  return <Container>
      <Header as="h1" subheader={eventSnapshot.data().title}>Close Event</Header>
      <p>Mark who was actually present at the event, so their time is logged correctly.</p>
      {groupedShifts.map(([group, shifts], i) => {
        return <React.Fragment key={i}>
          {group && <Header as="h2" content={group}/>}
          <Grid>
            {shifts.map((shift, i) => {
              return <Grid.Row key={`shift-${group}-${i}`}>
                <Grid.Column width={8}>
                  {rangeString(shift.startTime, shift.endTime)}
                </Grid.Column>
                <Grid.Column width={8}>
                  <UserSearch multiple defaultValue={state[shift.id]} onChange={(uids) => {
                    setState({...state, [shift.id]: uids})
                  }}/>
                </Grid.Column>
              </Grid.Row>
            })}
          </Grid>
        </React.Fragment>
      })}
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} />
          <Grid.Column width={8}>
            <Button content="Close event" icon="check" primary onClick={handleCloseEvent.bind(null, state)} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
  </Container>
};

export default CloseEventPage

const handleCloseEvent = window.closeEvent = async (attendees) => {
  console.log(await closeEvent({shiftAttendees: attendees}));
  navigate("..");
};