import React from "react";
import Divider from "semantic-ui-react/dist/es/elements/Divider";
import Icon from "semantic-ui-react/dist/es/elements/Icon";
import List from "semantic-ui-react/dist/es/elements/List";

const Footer = () => {
  return <>
    <Divider/>
    <List horizontal celled>
      <List.Item>
        The Kaleido app is open source.&nbsp;
        <a href="https://github.com/bigblind/kalendar">
          <Icon name="github" />See the code and contribute on GitHub
        </a>
      </List.Item>
      <List.Item>
        <a href="https://kaleido-maastricht.nl/">
          <Icon name="linkify" /> Kaleido website
        </a>
      </List.Item>
      <List.Item>
        <a href="https://www.facebook.com/KaleidoMaastricht/">
          <Icon name="facebook"/> Kaleido on Facebook
        </a>
      </List.Item>
    </List>
  </>
};

export default Footer
