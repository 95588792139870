import React from "react";
import firebase from "../firebase";
import Table from "semantic-ui-react/dist/es/collections/Table";
import Header from "semantic-ui-react/dist/es/elements/Header";
import Icon from "semantic-ui-react/dist/es/elements/Icon";
import Loader from "semantic-ui-react/dist/es/elements/Loader";
import {Link} from "@reach/router";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default class HoursOverview extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      users: [],
      totals: [],
      loading: true,
      url: ""
    }
  }
  
  async componentDidMount() {
    let usersPromise = firebase.firestore().collection("users").get();
    
    let currentMonth = (new Date()).getMonth();
    let currentYear = (new Date()).getFullYear();
    
    let months = [];
    
    let totalsPromise = Promise.all([0, 1, 2].map((i) => {
      let month = currentMonth - i;
      let year = currentYear;
      if (month < 0) {
        year -= 1;
        month = 12 + month
      }
      
      months.push([month, year]);
      
      return firebase.firestore().doc(`/log_totals/${year}_${month}`).get();
    }));
    
    let [usersSnapshot, totalsSnapshots] = await Promise.all([usersPromise, totalsPromise]);
    this.setState({
      users: usersSnapshot.docs,
      totals: totalsSnapshots,
      months: months,
      loading: false,
      url: createCSVUrl(usersSnapshot.docs, totalsSnapshots, months)
    });
  }
  
  render() {
    if(this.state.loading) {
      return <Loader size="huge" active />
    }
    
    return <>
      <Header as="h1">Member Hours</Header>
      <a href={this.state.url} download="hours.csv"><Icon name="arrow alternative circle down" /> Download data as CSV.</a>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            {this.state.months.map(([month, year]) => {
              return <Table.HeaderCell key={month + "_" + year}>{monthNames[month]} {year}</Table.HeaderCell>
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.users.map((user) => {
            let data = user.data();
            return <Table.Row key={user.id}>
              <Table.Cell>
                <Link to={`/logs/${user.id}`}>{data.name}</Link>
              </Table.Cell>
              {this.state.totals.map((total) => {
                let minutes = total.get(user.id) || 0;
                return <Table.Cell key={`${user.id}-${total.id}`}>
                  {Math.floor(minutes / 60).toString().padStart(2, "0")}:{(minutes % 60).toString().padStart(2, "0")}
                </Table.Cell>
              })}
            </Table.Row>
          })}
        </Table.Body>
      </Table>
    </>
  }
}

function createCSVUrl(users, totals, months) {
  let headers = "name," + months.map(([m, y]) => monthNames[m] + "_minutes").join(",") + "\n";
  let rows = users.map((user) => {
    return [user.data().name].concat(totals.map((t) => (t.get(user.id) || 0)));
  }).join("\n");
  
  let b = new Blob([headers, rows]);
  return URL.createObjectURL(b);
}