import React from "react";
import Header from "semantic-ui-react/dist/es/elements/Header";
import Container from "semantic-ui-react/dist/es/elements/Container";
import withState from "recompose/withState";
import {navigate} from "@reach/router";

import firebase from "../firebase";
import Editor from "./Editor";

const CreateEvent = ({saving, setSaving}) => {
  return <Container>
    <Header as="h1">Create an Event</Header>
    <Editor onSave={saveEvent.bind(null, setSaving)} saving={saving}/>
  </Container>
};

async function saveEvent(setSaving, event, shifts=[]){
  event.creator = firebase.auth().currentUser.uid;
  event.public = true; //this could be set to false to save an event as a draft.
  
  let docRef = await firebase.firestore().collection("/events").add(event);
  
  let shiftsCollection = firebase.firestore().collection("shifts");
  await Promise.all(shifts.map((shift) => {
    shiftsCollection.add({
      ...shift,
      event: docRef.id
    });
  }));
  
  let path = "/events/" + docRef.id;
  navigate(path);
}

export default withState("saving", "setSaving", false)(CreateEvent);