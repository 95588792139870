import React from "react";
import Button from "semantic-ui-react/dist/es/elements/Button";
import Popup from "semantic-ui-react/dist/es/modules/Popup";
import List from "semantic-ui-react/dist/es/elements/List";
import Label from "semantic-ui-react/dist/es/elements/Label";

import {useCollection, useDocument} from "react-firebase-hooks/firestore";

import firebase from "../firebase";

export default class Attendance extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loaded: false,
      shift: {
        attendees: {}
      }
    };
    
    this.toggleAttend = this.toggleAttend.bind(this);
  }
  
  async componentDidMount() {
    this.uid = firebase.auth().currentUser.uid;
    
    this.docRef = firebase.firestore().doc(`/shifts/${this.props.shiftId}`);
    this.unsubscribe = this.docRef.onSnapshot((snap) => {
      if(!snap.exists) {
        return;
      }
      
      this.setState({
        shift: snap.data(),
        isAttending: !!(snap.data().attendees || {})[this.uid],
        loaded: true
      });
    });
  }
  
  componentWillUnmount() {
    this.unsubscribe();
  }
  
  toggleAttend() {
    this.docRef.update({
      [`attendees.${this.uid}`]: !this.state.isAttending
    })
  }
  
  getCapacity() {
    let attendees = this.state.shift.attendees || {};
    let nAttendees = Object.keys(attendees).filter((uid) => attendees[uid]).length;
    let capacity = this.state.shift.capacity || 0;
    return [nAttendees, capacity];
  }
  
  canJoin() {
    let [nAttendees, capacity] = this.getCapacity();
    return (capacity <= 0 || nAttendees < capacity) &&
           this.state.shift.startTime.toDate() > new Date();
  }
  
  render() {
    let conditionalProps = this.state.isAttending ? {
      "content": "Leave",
      "icon": "remove user",
      "secondary": true
    } : {
      "content": "Join",
      "icon": "add user",
      "positive": true
    };
    
    if(this.props.compact) {
      conditionalProps = {
        ...conditionalProps,
        compact: true,
        size: "mini"
      };
    }
  
    return <List horizontal>
      <List.Item>
        <Button disabled={!this.state.loaded || (!this.state.isAttending && !this.canJoin())} {...conditionalProps} onClick={this.toggleAttend} />
      </List.Item>
      {this.getAttendees()}
    </List>
  }
  
  getAttendees() {
    if (!this.state.loaded) {
      return [];
    }
    
    let attendees = this.state.shift.attendees || {};
    let [nAttendees, capacity] = this.getCapacity();
    attendees = Object.keys(attendees).filter((uid) => attendees[uid]);
    
    if (this.props.compact) {
      let label = nAttendees;
      if(capacity > 0) {
        label = `${nAttendees}/${capacity}`
      }
      
      return <List.Item>
        <Popup trigger={
          <Label content={label}
                 color={this.canJoin() ? "blue" : "gray"}
                 horizontal/>
        }>
          <List>
            {attendees.map((uid) => {
              return <UserListItem uid={uid} />
            })}
          </List>
        </Popup>
      </List.Item>
    }
  
    return attendees.map((uid) => {
      return <List.Item key={uid}>
        <UserAvatar uid={uid}/>
      </List.Item>
    });
  }
}

const UserListItem = ({uid}) => {
  let {loading, value} = useDocument(firebase.firestore().doc(`users/${uid}`));
  return loading ? null : <List.Item image={{src: value.data().image_url}} header={value.data().name} />
};

class UserAvatar extends React.Component{
  constructor(props) {
    super(props);
    
    this.state = {
      user: null
    }
  }
  
  async componentDidMount() {
    let fb = await firebase;
    let snap = await fb.firestore().doc(`/users/${this.props.uid}`).get()
    this.setState({
      user: snap.data()
    });
  }
  
  render() {
    let user = (this.state.user || {});
    let image = <img src={user.image_url} style={{width: 32}}/>;
    return <Popup trigger={image} header={user.name} inverted position="top center" />
  }
}