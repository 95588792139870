import React from "react";
import Menu from "semantic-ui-react/dist/es/collections/Menu"
import Icon from "semantic-ui-react/dist/es/elements/Icon";
import Responsive from "semantic-ui-react/dist/es/addons/Responsive"
import withState from "recompose/withState";

import {Link} from "@reach/router"

import firebase from "./firebase";

import logo from "./kaleido.png"

import {UserConsumer} from "./AuthContainer";

const MenuBar = ({expanded, setExpanded}) => {
  let isMobile = window.innerWidth <= 768;
  return <Menu inverted attached="bottom" stackable>
    <Menu.Item {...isMobile ? {as: Link, to: "/"} : {}}>
      <img src={logo} alt="Home" />
      <Responsive maxWidth={768} style={{position: "absolute", right: "1em"}}>
        <Icon name={expanded ? "chevron up" : "bars"} onClick={() => setExpanded(!expanded)} size="large"/>
      </Responsive>
    </Menu.Item>
    {((!isMobile) || expanded) ? <>
      <Menu.Item as={Link} to="/" content="Events"
                 icon="calendar alternate outline"/>
      <Menu.Item as={Link} to="/events/new" content="Create Event"
                 icon="calendar plus"/>
      <Menu.Item as={Link} to="/logs" content="Time Log" icon="clock outline"/>
  
      <UserConsumer>
        {(user) => user.admin ?
          <Menu.Item as={Link} to="/admin" icon="key" content="Admin"/> : null}
      </UserConsumer>
  
      <Menu.Item position="right" content="Log Out" icon="sign out"
                 onClick={async () => {
                   let fb = await firebase;
                   fb.auth().signOut();
                 }}/>
    </> : null}
  </Menu>
};

export default withState("expanded", "setExpanded", window.innerWidth > 768)(MenuBar);