import React from "react";
import Form from "semantic-ui-react/dist/es/collections/Form";
import DatePicker from "../forms/DateTimePicker";

import ShiftsEditor from "./EventShiftsEditor"

import "react-datepicker/dist/react-datepicker.min.css"

const noop = () => {};
export default class EventEditor extends React.Component{
  constructor(props) {
    super(props);
    
    this.state = {
      event: props.event || {
        name: "",
        startTime: new Date(),
        endTime: new Date(),
        type: "meeting",
        description: "",
        locationName: "Kaleido Office",
        address: "Maasboulevard 1, Maastricht"
      },
      
      shifts: this.props.shifts || []
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  render() {
    return <Form style={{maxWidth: 780}}>
      <Form.Input id="name" name="name" label="Name" onChange={this.handleChange} value={this.state.event.name}/>
      <Form.TextArea id="description" name="description" label="Description" onChange={this.handleChange} value={this.state.event.description}/>
      <Form.Group widths='equal'>
        {this.getDateTimeInput("startTime", "Start Time")}
        {this.getDateTimeInput("endTime", "End Time")}
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Input id="LocationName" name="locationName" label="Location Name" onChange={this.handleChange} value={this.state.event.locationName}/>
        <Form.Input id="address" name="address" label="Address" onChange={this.handleChange} value={this.state.event.address}/>
      </Form.Group>
      {this.typeInput()}
      {this.state.event.type === "event" ? <ShiftsEditor shifts={this.state.shifts}
                                                         event={this.state.event}
                                                         onChange={(shifts) => this.setState({shifts})} /> : null }
      <Form.Button content="Save"
                   onClick={() => (this.props.onSave || noop)(this.state.event, this.state.shifts)}
                   positive
                   icon="save"
                   loading={this.props.saving}/>
    </Form>
  }
  
  typeInput() {
    if(this.props.isUpdate) {
      return null;
    }
    
    return <Form.Group grouped>
      <label>Event Type</label>
      <Form.Radio name="type"
                  value="meeting"
                  label="Meeting"
                  checked={this.state.event.type === "meeting"}
                  onChange={this.handleChange}/>
      <Form.Radio name="type"
                  value="event"
                  label="Event with multiple shifts"
                  checked={this.state.event.type === "event"}
                  onChange={this.handleChange}/>
    </Form.Group>
  }
  
  handleChange(e, {name, value}) {
    this.setState({
      event: {...this.state.event, [name]: value}
    }, () => {
      (this.props.onChange || noop)(this.state.event)
    });
  }
  
  getDateTimeInput(name, label) {
    return <Form.Field>
      <label htmlFor={name}>{label}</label>
      <DatePicker
        value={this.state.event[name]}
        onChange={(value) => this.handleChange(null, {name, value})}
        includeTime={true}
      />
    </Form.Field>
  }
}