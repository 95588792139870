import React from "react";
import Header from "semantic-ui-react/dist/es/elements/Header";
import Container from "semantic-ui-react/dist/es/elements/Container";
import Loader from "semantic-ui-react/dist/es/elements/Loader";
import Button from "semantic-ui-react/dist/es/elements/Button";
import Table from "semantic-ui-react/dist/es/collections/Table";
import Input from "semantic-ui-react/dist/es/elements/Input";
import DatePicker from "../forms/DateTimePicker";
import InputMask from 'react-input-mask';

import firebase from "../firebase";

export default class UserLogs extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      date: new Date(),
      message: "",
      minutes: "00:15",
      
      logs: [],
      loading: true
    };
    
    this.addLog = this.addLog.bind(this);
    this.deleteLog = this.deleteLog.bind(this);
  }
  
  async componentDidMount() {
    let fb = this.fb = await firebase;
    
    this.colRef = fb.firestore().collection(`/users/${this.getUid()}/logs`)
    this.colRef.onSnapshot((snapshot) => {
      this.setState({
        logs: snapshot.docs,
        loading: false
      })
    });
    
    if(fb.auth().currentUser.uid !== this.getUid()) {
      let user = await fb.firestore().doc(`/users/${this.getUid()}`).get();
      this.setState({
        name: user.data().name
      })
    }
  }
  
  addLog() {
    this.colRef.add({
      date: this.state.date,
      minutes: parseMinutes(this.state.minutes),
      message: this.state.message,
    });
    
    this.setState({
      date: new Date(),
      minutes: "00:15",
      message: ""
    });
  }
  
  deleteLog(id) {
    this.colRef.doc(id).delete();
  }
  
  getUid(){
    return this.props.uid ? this.props.uid : this.fb.auth().currentUser.uid;
  }
  
  render() {
    if(this.state.loading) {
      return <Loader size="huge" active content="Loading..." />;
    }
    
    let userHeader = "Your";
    if(this.fb.auth().currentUser.uid !== this.getUid()) {
      userHeader = `${this.state.name}'s`;
    }
    
    return <Container>
      <Header as="h1" size="h1">{userHeader} Logged Time</Header>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Hours</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        
        <tbody>
        {this.getFormRow()}
        {this.state.logs.map((log) => <Table.Row key={log.id}>
          <Table.Cell>{log.data().date.toDate().toLocaleDateString()}</Table.Cell>
          <Table.Cell>{formatMinutes(log.data().minutes)}</Table.Cell>
          <Table.Cell>{log.data().message} </Table.Cell>
          <Table.Cell>
            <Button icon="trash" aria-label="Delete" onClick={() => this.deleteLog(log.id)}
                    size="tiny" negative circular compact floated="right" />
          </Table.Cell>
        </Table.Row>)}
        </tbody>
      </Table>
    </Container>
  }
  
  getFormRow() {
    return <Table.Row>
      <Table.Cell>
        <DatePicker selected={this.state.date}
                    onChange={(value) => this.setState({date: value})}
                    includeTime={false} />
      </Table.Cell>
      <Table.Cell>
        <InputMask value={this.state.minutes}
                   onChange={(e) => this.setState({minutes: e.target.value})}
                   mask="99:99"/>
      </Table.Cell>
      <Table.Cell>
        <Input type="text" value={this.state.message}
               onChange={(e) => this.setState({message: e.target.value})}/>
      </Table.Cell>
      <Table.Cell>
        <Button primary content="Log Time" icon="plus" onClick={this.addLog}
                disabled={this.state.message.trim().length === 0 || this.state.minutes.length !== 5}/>
      </Table.Cell>
    </Table.Row>
  }
}

const formatMinutes = (mins) => `${(Math.floor(mins / 60)).toString().padStart(2, "0")}:${(mins % 60).toString().padStart(2, "0")}`;
const parseMinutes = (hoursAndMinutes) => {
  let [hours, minutes] = hoursAndMinutes.split(":");
  return parseInt(hours) * 60 + parseInt(minutes);
}