import React from "react";
import Header from "semantic-ui-react/dist/es/elements/Header";
import Loader from "semantic-ui-react/dist/es/elements/Loader";
import List from "semantic-ui-react/dist/es/elements/List";
import Image from "semantic-ui-react/dist/es/elements/Image";
import Button from "semantic-ui-react/dist/es/elements/Button";
import Search from "semantic-ui-react/dist/es/modules/Search";
import Fuse from "fuse.js";

import firebase from "../firebase";


export default class AdminsManager extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      users: [],
      loading: true,
      
      search: "",
      results: [],
      selectedUser: null
    };
  }
  
  componentDidMount() {
    firebase.firestore().collection("users").onSnapshot((users) => {
      this.setState({
        loading: false,
        users: users.docs
      });
      
      this.fuse = new Fuse(users.docs.map(d => {
        let data = d.data();
        data.id = d.id;
        return data;
      }), {
        keys: ["name"],
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 2,
      })
    })
  }
  
  handleSearch(e, {value}) {
    
    let results = this.fuse.search(value);
    
    this.setState({
      search: value,
      results: results,
      selectedUser: null
    })
  }
  
  handleSelectResult(e, {result}) {
    console.log("got result", result);
    this.setState({
      selectedUser: result,
      search: result.title
    })
  }
  
  addAdmin() {
    firebase.firestore().doc(`users/${this.state.selectedUser.id}`).update({admin: true});
    this.setState({selectedUser: null, search: ""});
  }
  
  async removeAdmin(id) {
    firebase.firestore().doc(`users/${id}`).update({admin: false});
  }
  
  render() {
    if(this.state.loading) {
      return <Loader size="huge" content="Loading user list" />
    }
    return <>
      <Header as="h1" size="huge">Admins</Header>
      <List verticalAlign="middle" divided style={{maxWidth: 400}}>
        {this.state.users.filter((u) => u.data().admin).map((doc) => {
          let data = doc.data();
          return <List.Item key={doc.id} divided>
            <Image avatar src={data.image_url} />
            <List.Content>{data.name}</List.Content>
            <Button size="tiny" negative floated="right" icon="x" basic circular
                    onClick={this.removeAdmin.bind(this, doc.id)}/>
          </List.Item>
        })}
      </List>
      <Header as="h2">Add Admin</Header>
      <List horizontal>
        <List.Item>
          <Search value={this.state.search} onSearchChange={this.handleSearch.bind(this)}
                  results={this.state.results.map((user) => {
                     return {title: user.name, image: user.image_url, id: user.id}
                  })} onResultSelect={this.handleSelectResult.bind(this)}/>
        </List.Item>
        <List.Item>
        <Button disabled={!this.state.selectedUser} icon="user" circular
                positive content="Add Admin" onClick={this.addAdmin.bind(this)}/>
        </List.Item>
      </List>
    </>
  }
}

