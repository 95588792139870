import React from "react";
import Loader from "semantic-ui-react/dist/es/elements/Loader"
import Button from "semantic-ui-react/dist/es/elements/Button";

import firebase from "./firebase";

const {Provider, Consumer} = React.createContext(null);


export const UserConsumer = Consumer;

export default class AuthContainer extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
      loaded: false,
      user: undefined
    };
    
    this.handleMessage = this.handleMessage.bind(this);
    this.signIn = this.signIn.bind(this);
  }
  
  async componentDidMount() {
    let fb = await firebase;
    this.unsubscribe = fb.auth().onAuthStateChanged(async (user) => {
      let uData = null;
      if(user) {
        let userDoc = await fb.firestore().doc(`/users/${user.uid}`).get();
        uData = userDoc.data();
        uData.uid = user.uid;
      }
      
      this.setState({
        loaded: true,
        user: uData
      });
    });
    
    window.addEventListener("message", this.handleMessage);
  }
  
  componentWillUnmount() {
    this.unsubscribe();
    window.removeEventListener("message", this.handleMessage)
  }
  
  signIn(){
    this.loginWindow = window.open("https://app.kaleido-maastricht.nl/oauth/login", "login", "width=400,height=400");
  }
  
  async handleMessage(e) {
    if(e.origin !== "https://app.kaleido-maastricht.nl"){
      console.log("Received a message from a different origin.", e.origin);
    }
    let token = e.data;
    
    if(!this.loginWindow) {
      return
    }
    
    let fb = await firebase;
    fb.auth().signInWithCustomToken(token);
    this.loginWindow.close();
  }
  
  render() {
    if(!this.state.loaded) {
      return <Loader active size="huge" />
    }
    
    if(!this.state.user) {
      return <Button icon="slack" content="Sign In with Slack" onClick={this.signIn}/>
    }
    
    return <Provider value={this.state.user}>{this.props.children}</Provider>
  }
}