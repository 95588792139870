import React from "react";
import Card from "semantic-ui-react/dist/es/views/Card";
import Container from "semantic-ui-react/dist/es/elements/Container";
import Loader from "semantic-ui-react/dist/es/elements/Loader";
import Message from "semantic-ui-react/dist/es/collections/Message";
import Icon from "semantic-ui-react/dist/es/elements/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button";
import Grid from "semantic-ui-react/dist/es/collections/Grid";
import {Link, navigate} from "@reach/router";

import firebase from "../firebase";
import {rangeString} from "./helpers";
import {UserConsumer} from "../AuthContainer";

import Attendance from "./Attendance";
import EventShifts from "./EventShifts";

const EventPage = ({event, deleteEvent}) => {
  console.log(event);
  return <Card fluid>
    <Card.Content>
      <Card.Header as="h1">
        <Icon name="calendar" /> &nbsp; {event.name}
        <UserConsumer>
          {(user) => (user.uid === event.creator || user.admin) ? <>
          <Button icon="trash" floated="right" size="mini" compact color="red" onClick={deleteEvent}/>
          <Button icon="pencil" floated="right" size="mini" compact as={Link} to="edit"/>
          <Button icon="check" floated="right" size="mini" compact as={Link} to="close" />
          </> : null}
        </UserConsumer>
      </Card.Header>
    </Card.Content>
    <Card.Content>
      <Card.Meta>
        <IconAndContent iconName="clock outline">{rangeString(event.startTime.toDate(), event.endTime.toDate())}</IconAndContent>
      </Card.Meta>
    </Card.Content>
    <Card.Content>
      <Card.Meta>
        <IconAndContent iconName="map marker alternate">
          <div><strong>{event.locationName}</strong></div>
          <div>{event.address}</div>
        </IconAndContent>
      </Card.Meta>
    </Card.Content>
    <Card.Content>
      <Card.Meta>
        {event.shift ? <Attendance shiftId={event.shift}/> : <EventShifts eventId={event.id} />}
      </Card.Meta>
    </Card.Content>
    <Card.Content>
      <Card.Description>
        <div style={{whiteSpace:"pre-wrap"}}>{event.description}</div>
      </Card.Description>
    </Card.Content>
  </Card>
};

const IconAndContent = ({iconName, children}) => {
  return <Grid>
    <Grid.Row>
      <Grid.Column width="1" verticalAlign="middle" stretch><Icon name={iconName} /></Grid.Column>
      <Grid.Column width="11" verticalAlign="middle" stretch>{children}</Grid.Column>
    </Grid.Row>
  </Grid>
};

class EventContainer extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
      loading: true,
      event: null,
      error: null,
    }
  }
  
  componentDidMount() {
    this.docRef = firebase.firestore().doc(`/events/${this.props.id}`)
    this.unsubscribe = this.docRef.onSnapshot((snapshot) => {
      if(!snapshot.exists) {
        this.setState({
          error: true
        });
        return;
      }
      let event = snapshot.data();
      event.id = snapshot.id;
      
      this.setState({
        loading: !event,
        event: event,
      });
    }, (err) => {
      this.setState({
        loading: false,
        error: err
      })
    });
  }
  
  componentWillUnmount() {
    this.unsubscribe()
  }
  
  deleteEvent() {
    if(!window.confirm("Are you sure you want to delete this event?")) {
      return;
    }
    
    this.setState({loading: true}, async () => {
      await this.docRef.delete();
      navigate("/");
    });
  }
  
  render() {
    if(this.state.loading) {
      return <Loader active size="huge"/>
    }
    
    if(this.state.error) {
      return <Message error header="We couldn't load the event." list={[
        "The event may not exist",
        "The event may have been deleted",
        "You may not have permission to view the event"
      ]}/>
    }
    
    return <Container>
      <EventPage event={this.state.event} deleteEvent={this.deleteEvent.bind(this)}/>
    </Container>
  }
}

export default EventContainer;