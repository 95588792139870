import React from "react";
import Input from "semantic-ui-react/dist/es/elements/Input"
import DatePicker from "react-datepicker";


export default class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      value: props.defaultValue
    };
    
    if(props.hasOwnProperty("value")) {
      this.state.value = props.value;
    }
    
    this.supportsNativeInputs = {
      dateTime: supportsDateTimeInput("datetime-local"),
      date: supportsDateTimeInput("date")
    };
  
    this.handleChange = (value) => {
      this.setState({value}, () => !isNaN(value.getTime()) && props.onChange && props.onChange(value));
    }
  }
  
  static getDerivedStateFromProps(props) {
    if(props.hasOwnProperty("value")) {
      return {value: props.value};
    }
    
    return {};
  }
  
  render() {
    if((this.props.includeTime && this.supportsNativeInputs.dateTime) ||
      (!this.props.includeTime && this.supportsNativeInputs.date)) {
      return this.renderNativeInput();
    } else {
      return this.renderFallback();
    }
  }
  
  renderNativeInput() {
    return <Input type={this.props.includeTime ? "datetime-local" : "date"}
                  defaultValue={this.state.value && this.state.value.toISOString().split(".")[0]}
                  onChange={(e) => this.handleChange(new Date(e.target.value))}/>
  }
  
  renderFallback() {
    return <DatePicker
      value={this.state.value}
      selected={this.state.value}
      onChange={this.handleChange}
      showTimeSelect={this.props.includeTime}
      timeFormat="HH:mm"
      timeIntervals={15}
      dateFormat={"MMMM d, yyyy" + (this.props.includeTime ? " HH:mm" : "")}
    />
  }
}

// For more info on how this works, and why such complexity is needed, check:
// https://stackoverflow.com/questions/10193294/how-can-i-tell-if-a-browser-supports-input-type-dat
function supportsDateTimeInput(type) {
  let input = document.createElement('input');
  input.setAttribute('type', type);
  
  let notADateValue = 'not-a-date';
  input.setAttribute('value', notADateValue);
  
  return (input.value !== notADateValue);
}