import day from "dayjs";

window.dayjs = day;

export const rangeString = (fromDate, toDate) => {
  fromDate = day(fromDate);
  toDate = day(toDate);
  let now = day();
  
  let fromYear = "YYYY,";
  let toYear = "YYYY,";
  let fromDay = "MMMM D,";
  let toDay = "MMMM D,";
  let fromTime = "hh:mm A";
  let toTime = "hh:mm A";
  
  let fromRel = relativeDay(fromDate);
  let toRel = relativeDay(toDate);
  fromDay = fromRel || fromDay;
  toDay = toRel || toDay;
  
  if(fromRel || toRel) {
    fromYear = null;
    toYear = null;
  }
  
  if(fromDate.isSame(toDate, "day") || fromDate.add(12, "hours").isSame(toDate, "day")) {
    toDay = null;
    toYear = null;
  }
  
  if(fromDate.year() === now.year()) {
    fromYear = null;
    toYear = null;
  } else if (fromDate.year() === toDate.year()) {
    fromYear = null;
  }
  
  return [fromDate.format(makeFormat(fromDay, fromYear, fromTime)),
          toDate.format(makeFormat(toDay, toYear, toTime))].join(" - ");
};

const relativeDay = (d) => {
  let now = day();
  if(now.isSame(d, "day")) {
    return "[today,]"
  }
  
  if(now.add(1, "day").isSame(d, "day")) {
    return "[tomorrow,]";
  }
  
  if(now.subtract(1, "day").isSame(d, "day")) {
    return "[yesterday,]"
  }
  
  return null;
};

const makeFormat = (...parts) => {
  return parts.filter((part) => !!part).join(" ");
};